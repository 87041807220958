import { Layout } from '../components/LogoutLayout';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button'; 
import { useAuth } from '@/contexts/auth';

export const Logout = () => {
  const { logout, isLoggingOut } = useAuth(); 
  return (
    <Layout title='Log out of your account'>
      <Suspense fallback={<CircularProgress size={80} />}>
        <Outlet />
      </Suspense>
      {/* Logout button */}
      <Button
        variant='contained'
        color='primary'
        onClick={() => logout()}
        disabled={isLoggingOut}
        style={{ marginTop: '5px' }}
      >
        {isLoggingOut ? 'Logging out...' : 'Log out'}
      </Button>
    </Layout>
  );
};
