import { Route, Routes, Navigate } from 'react-router-dom';

import ProtectedRoutes from './ProtectedRoutes';
import { Login } from '../features/auth/routes/Login';
import { Logout } from '../features/auth/routes/Logout';
import { LoginForm } from '../features/auth/components/LoginForm';
import { SmsCodeForm } from '../features/auth/components/SmsCodeForm';

import { NotFound } from '@/features/misc';
import { PrivacyPolicyEN } from '../features/misc/routes/PrivacyPolicyEN';
import { PrivacyPolicyFR } from '../features/misc/routes/PrivacyPolicyFR';
import { TermsAndConditionsEN } from '../features/misc/routes/TermsAndConditionsEN';
import { TermsAndConditionsFR } from '../features/misc/routes/TermsAndConditionsFR';
import { Block } from '../features/block/routes/Block';
import { VehiclesLive } from '../features/block/routes/block-sections/live/VehiclesLive';
import { VehiclesUpcoming } from '../features/block/routes/block-sections/upComing/VehiclesUpcoming';
import { VehiclesOffers } from '../features/block/routes/block-sections/offers/VehiclesOffers';
import { VehiclesPurchases } from '../features/block/routes/block-sections/purchases/VehiclesPurchases';
import { VehicleDetailsUpComing } from '../features/block/routes/block-sections/upComing/VehicleDetailsUpComing';
import { VehicleDetailsLive } from '../features/block/routes/block-sections/live/VehicleDetailsLive';
import { VehicleDetailsOffers } from '../features/block/routes/block-sections/offers/VehicleDetailsOffers';
import { VehicleDetailsPurchases } from '../features/block/routes/block-sections/purchases/VehicleDetailsPurchases';
import { VehiclesMarketHistory } from '../features/block/routes/block-sections/marketHistory/VehiclesMarketHistory';
import { VehicleDetailsMarketHistory } from '../features/block/routes/block-sections/marketHistory/VehicleDetailsMarketHistory';
import { useAuth } from '../contexts/auth';

export const AppRoutes = () => {
    const { user } = useAuth();

    return (
        <Routes>
            {user ? (
                <>
                    <Route path='/login/*' element={<Navigate to='/live' />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route element={<Block />}>
                            <Route path='upcoming' element={<VehiclesUpcoming />} />
                            <Route
                                path='/upcoming/vehicle-details/:id'
                                element={<VehicleDetailsUpComing />}
                            />

                            <Route path='/live' element={<VehiclesLive />} />
                            <Route path='/live/vehicle-details/:id' element={<VehicleDetailsLive />} />

                            <Route path='offers' element={<VehiclesOffers />} />
                            <Route path='/offers/vehicle-details/:id' element={<VehicleDetailsOffers />} />

                            <Route path='purchases' element={<VehiclesPurchases />} />
                            <Route
                                path='/purchases/vehicle-details/:id'
                                element={<VehicleDetailsPurchases />}
                            />

                            <Route path='marketHistory' element={<VehiclesMarketHistory />} />
                            <Route path='/marketHistory/vehicle-details/:id' element={<VehicleDetailsMarketHistory />} />
                            
                            <Route path='/terms-and-conditions' element={<TermsAndConditionsEN />} />
                            <Route path='/termes-et-conditions' element={<TermsAndConditionsFR />} />

                            <Route path='/privacy-policy' element={<PrivacyPolicyEN />} />
                            <Route path='/politique-de-confidentialité' element={<PrivacyPolicyFR />} />

                            <Route path='*' element={<Navigate to='/live' />} />
                        </Route>                        
                    </Route>
                    <Route path='*' element={<NotFound />} />
                    
                </>
            ) : (
                <Route path='/login' element={<Login />}>
                    <Route index element={<LoginForm />} />
                    <Route path='sms-code' element={<SmsCodeForm />} />
                    <Route path='*' element={<Navigate to='/login' />} />
                </Route>
            )}
            <Route path='/logout' element={<Logout />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditionsEN />} />
            <Route path='/termes-et-conditions' element={<TermsAndConditionsFR />} />
            <Route path='/privacy-policy' element={<PrivacyPolicyEN />} />
            <Route path='/politique-de-confidentialité' element={<PrivacyPolicyFR />} />
            <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
    );
};

